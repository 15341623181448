export const MENU = [
  {
    name: 'О нас',
    link: '/',
  },
  {
    name: 'Способы оплаты',
    link: '/',
  },
  {
    name: 'Гарантии',
    link: '/',
  },
  {
    name: 'Правила работы в праздничные дни',
    link: '/',
  },
  {
    name: 'Корпоративным клиентам',
    link: '/',
  },
  {
    name: 'Доставка',
    link: '/',
  },
  {
    name: 'Доставка по номеру телефона',
    link: '/',
  },
  {
    name: 'Доставка цветов по станциям метро',
    link: '/',
  },
  {
    name: 'Акции и бонусы',
    link: '/',
  },
  {
    name: 'Блог',
    link: '/',
  },
  {
    name: 'Отзывы',
    link: '/',
  },
  {
    name: 'Фотографии клиентов',
    link: '/',
  },
  {
    name: 'Публичная оферта',
    link: '/',
  },
  {
    name: 'Каталог',
    link: '/',
  },
  {
    name: 'Контакты',
    link: '/',
  },
  {
    name: 'Вопрос-ответ',
    link: '/',
  },
  {
    name: 'Политика возврата',
    link: '/',
  },
  {
    name: 'Email подписка',
    link: '/',
  },
]

export const SOCIALS = [
  {
    link: 'https://vk.com/club17821251',
    icon: 'vk',
    label: 'Vkontakte',
  },
  {
    link: 'https://www.facebook.com/flor2u.ru/',
    icon: 'fb',
    label: 'Facebook',
  },
  {
    link: 'https://www.instagram.com/flor2u.ru/',
    icon: 'inst',
    label: 'Instagram',
  },
  {
    link: 'https://plus.google.com/communities/104389919370537236384',
    icon: 'g+',
    label: 'Google Plus',
  },
  {
    link: 'https://ru.pinterest.com/flor2u177/pins/',
    icon: 'pinterest',
    label: 'Pinterest',
  },
  {
    link: 'https://ok.ru/group/53738569138312',
    icon: 'ok',
    label: 'Odnoklassniki',
  },
  {
    link: 'https://twitter.com/Flor2uZabota',
    icon: 'twit',
    label: 'Twitter',
  },
]
