<template>
  <footer class="footer">
    <div class="container">
      <div v-once class="footer__blocks">
        <nav class="footer__menu">
          <ul class="footer__list">
            <li
              v-for="(item, i) in menu"
              :key="`menu-item-${i}`"
              class="footer__list-item"
            >
              <nuxt-link :to="item.link" class="footer__list-link">
                {{ item.name }}
              </nuxt-link>
            </li>
          </ul>
        </nav>

        <address class="contact">
          <div class="contact__item">
            <CIcon name="phone" class="contact__icon" />
            <a href="tel:84951206586" class="contact__link">
              8 (495) 120-65-86
            </a>
          </div>
          <div class="contact__item">
            <CIcon name="phone" class="contact__icon" />
            <a href="tel:88006004360" class="contact__link">
              +7 (800) 600-43-60
            </a>
          </div>
          <div class="contact__item">
            <button type="button" class="contact__link">Заказать звонок</button>
          </div>
          <div class="contact__item">
            <CIcon name="mail" class="contact__icon" />
            <a href="mailto:info@flor2u.ru" class="contact__link">
              info@flor2u.ru
            </a>
          </div>
          <div class="contact__item">
            <CIcon name="placemark" class="contact__icon" />
            <a href="#" class="contact__link">
              Москва, Павловская 27/29 стр.2, эт/ком 1/8
            </a>
          </div>
          <div class="contact__item">
            <CIcon name="share" class="contact__icon" />
            <span class="contact__text">Мы в соцсетях:</span>
            <ul class="contact__socials">
              <li
                v-for="(social, i) in socials"
                :key="`social_${i}`"
                class="contact__socials-item"
              >
                <a
                  :href="social.link"
                  target="_blank"
                  class="contact__socials-link"
                  rel="noreferrer"
                  :aria-label="social.label"
                >
                  <CIcon class="contact__socials-icon" :name="social.icon" />
                </a>
              </li>
            </ul>
          </div>
        </address>
      </div>

      <div class="copyright">
        <div class="copyright__pay">
          <CIcon class="copyright__pay-icon visa" name="visa" />
          <CIcon class="copyright__pay-icon mastercard" name="mastercard" />
          <CIcon class="copyright__pay-icon webmoney" name="webmoney" />
          <CIcon class="copyright__pay-icon yamoney" name="yamoney" />
          <CIcon class="copyright__pay-icon qiwi" name="qiwi" />
        </div>
        <p class="copyright__text">
          Так же, Вы можете оплатить наличными при получении, либо выбрать
          другой
          <nuxt-link to="/">способ оплаты.</nuxt-link>
        </p>
        <p class="copyright__text">
          © 2020 Flor2u.ru - доставка цветов и подарков в Москве.
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
/* TODO: разбить на компоненты */
import { MENU, SOCIALS } from '@/components/Footer/FooterModules/footerUttils'
import CIcon from '@/components/common/CIcon'

export default {
  name: 'Footer',

  components: {
    CIcon,
  },

  created() {
    this.menu = MENU
    this.socials = SOCIALS
  },
}
</script>

<style lang="scss" scoped>
.footer {
  padding: rem(36) 0 0 0;
  background-color: $color-gray4;

  &__blocks {
    display: flex;
    width: 100%;
  }

  &__contacts {
    width: 100%;
    min-width: 25%;
    max-width: 25%;
  }

  &__menu {
    display: flex;
    flex-wrap: wrap;
  }

  &__list {
    width: 100%;
    margin: 0;
    padding: 0 rem(16) 0 0;
    column-count: 4;
    list-style: none;

    &-item {
      margin-bottom: rem(12);
    }

    &-link {
      color: $color-gray5;
      line-height: rem(16);
      text-decoration: none;
      transition: color $default-transition;
      &:hover {
        color: $color-green;
        text-decoration: underline;
      }
    }
  }
}

.contact {
  padding: 0 0 0 rem(16);
  border-left: rem(1) solid rgba($color-gray5, 0.3);
  &__item {
    display: flex;
    align-items: flex-start;
    margin-bottom: rem(12);
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__icon {
    height: rem(14);
    margin: 0 rem(8) 0 0;
    fill: $color-gray5;
    @include base-width(14);
  }

  &__link {
    color: $color-gray5;
    font-size: rem(13);
    line-height: rem(16);
    text-decoration: none;
    transition: color $default-transition;
    &:hover {
      color: $color-green;
      text-decoration: underline;
    }
  }

  &__text {
    display: inline-block;
    margin-right: rem(12);
    color: $color-gray5;
    font-size: rem(13);
    line-height: rem(16);
  }

  &__socials {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 40%;
    margin: 0;
    padding: 0;
    list-style: none;

    &-item {
      margin: 0 rem(4) rem(4) rem(4);
    }

    &-icon {
      height: rem(20);
      @include base-width(20);
    }
  }
}

.copyright {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: rem(72) 0 0 0;
  border-top: rem(1) solid rgba($color-gray5, 0.3);
  &__pay {
    display: flex;
    align-items: center;
    height: rem(40);
    overflow: hidden;

    &-icon {
      margin: 0 rem(20) 0 0;
      &.visa {
        height: rem(40);
        @include base-width(40);
      }

      &.mastercard {
        height: rem(36);
        @include base-width(36);
      }

      &.webmoney {
        height: rem(70);
        @include base-width(70);
      }

      &.yamoney {
        height: rem(50);
        @include base-width(50);
      }

      &.qiwi {
        height: rem(50);
        @include base-width(50);
      }
    }
  }

  &__text {
    flex-shrink: 100;
    margin: rem(8) 0 rem(8) rem(16);
    color: $color-gray5;
    font-size: rem(12);

    a {
      color: $color-gray5;
      transition: color $default-transition;
      &:hover {
        color: $color-green;
      }
    }
  }
}

@media only screen and (max-width: $breakpoint-desktop) {
  .footer {
    &__menu {
      width: 65%;
    }
    &__list {
      width: 100%;
      column-count: 2;
      &-item {
        margin: 0 rem(24) rem(12) 0;
      }
    }
  }

  .copyright {
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    padding-bottom: rem(12);
    &__text {
      order: 2;
      width: 100%;
      max-width: 100%;
      margin: 0;
      text-align: center;
    }
    &__pay {
      order: 1;
      margin: 0 auto;
    }
  }
}

@media only screen and (max-width: $breakpoint-tablet) {
  .footer {
    &__blocks {
      flex-wrap: wrap;
    }
    &__menu {
      width: 100%;
    }
  }

  .contact {
    width: 100%;
    padding: rem(12) 0 0 0;
    border-top: rem(1) solid rgba($color-gray5, 0.3);
    border-left: unset;
  }

  .copyright {
    margin: rem(8) 0 0 0;
    &__pay {
      flex-wrap: wrap;
      justify-content: center;
      height: auto;
      min-height: rem(40);
    }
  }
}
</style>
